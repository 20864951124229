/* global */

$(document).foundation();

$.isInViewport = el => {
  const elementTop = $(el).offset().top + 500;
  const elementBottom = elementTop + $(el).outerHeight();
  const viewportTop = $(window).scrollTop();
  const viewportBottom = viewportTop + $(window).height();
  return elementBottom > viewportTop && elementTop < viewportBottom;
};

$(() => {
  $.slidebars();
  /* eslint-disable */
  $("#menu-slidebars-menu > li.menu-item-has-children > a").click(e => {
    e.preventDefault();
    const mobileMenuItem = e.target;
    if ($(mobileMenuItem).hasClass("active")) {
      $(mobileMenuItem).removeClass("active");
      $(mobileMenuItem)
        .next()
        .stop()
        .slideUp();
    } else {
      $(mobileMenuItem).addClass("active");
      $(mobileMenuItem)
        .next()
        .stop()
        .slideDown();
    }
  });

  if ($("#menu-slidebars-menu > li.current-menu-parent").length) {
    $("#menu-slidebars-menu > li.current-menu-parent > a").addClass("active");
  }

  $('body').on('click', '.faq-toggle-btn', function () {
    $(this).toggleClass('opened');
    $(this).parent().siblings('.faq-answers').slideToggle(500);
  });


  $('body').on('click', '.faq-questions > p', function () {
    $(this).siblings('.faq-toggle-btn').toggleClass('opened');
    $(this).parent().siblings('.faq-answers').slideToggle(500);
  });


  $(".owl-carousel").owlCarousel({
    loop: true,
    margin: 10,
    nav: true,
    items: 1,
    autoHeight: true,
  });

  if ($("#menu-header-menu-top").length) {
    $("#menu-header-menu-top li:last")
      .html("")
      .append(
        '<div class="header-social-icons"><a target="_blank" href="https://www.instagram.com/stanleyparkpav/"><img src="/wp-content/themes/tourlabs-stanley/assets/icons/icon-instagram-alt.png" /></a> <a target="_blank" href="https://www.pinterest.co.kr/stanleypavilion/"><img src="/wp-content/themes/tourlabs-stanley/assets/icons/icon-pinterest.png" /></a> <a target="_blank" href="https://twitter.com/stanleyparkpav"><img src="/wp-content/themes/tourlabs-stanley/assets/icons/icon-twitter.png" /></a> <a target="_blank" href="https://www.facebook.com/stanleyparkpav"><img src="/wp-content/themes/tourlabs-stanley/assets/icons/icon-facebook.png" /></a></div>'
      );
  }

  $(document).on("click", 'a[href^="#"]', event => {
    event.preventDefault();
    $("html, body").animate(
      {
        scrollTop: $(
          `a[name="${$(event.currentTarget).attr("href")}"]`
        ).offset().top
      },
      500
    );
  });

  // subpage scroll to 
  $(".content-nav-subpage .subnav-menu a").click(function (e) {
    var id = $(this).attr('href');
    if (id.includes("#")) {
      e.preventDefault();
      $([document.documentElement, document.body]).animate({
        scrollTop: $(id).offset().top - 120
      }, 1300);
    }

  });
 
  /* eslint-disable */
  StickyHeader();
  $(window).scroll(function (event) {
    StickyHeader();
  });


});

/* global AOS */
$(window).on("load", () => {
  AOS.init();
});

const StickyHeader = () => {
  var scroll = $(window).scrollTop();
  // Do something
  if (scroll > 35) {
    $(".header-nav-wrapper").addClass("fixed-header-nav");
    $(".header-separator-line").addClass("fixed-line");
    $(".main-content").css("marginTop", "100px");
  } else {
    $(".header-nav-wrapper").removeClass("fixed-header-nav");
    $(".header-separator-line").removeClass("fixed-line");
    $(".main-content").css("marginTop", "0");
  }
};


window.onload = function () {
  // gallery
  /* eslint-disable */
  var $grid = $(".row-gallery").masonry({
    itemSelector: ".grid-item",
    gutter: 5,
    horizontalOrder: true
  });

  $grid.masonry();

  $('[data-fancybox="gallery"]').fancybox({
    infobar: false,
    buttons: false,
    loop: true,
    closeClickOutside : true,
    mobile : {
      clickSlide : "close"
  },
    beforeShow: function () {
      $(".caption--image").remove();
      $(".fancybox-content").css("overflow", "visible");
    },
    afterLoad: function (instance, current) {
      current.$content.append(
        '<a data-fancybox-next class="button-next" href="javascript:;"></a><a data-fancybox-prev class="button-previous" href="javascript:;"></a>'
      );
    },
    afterShow: function (instance, current) {

      var caption = $(".fancybox-caption"),
        innerCaption = caption.clone().addClass("caption--image");
      $(".fancybox-slide--current .fancybox-content").append(innerCaption);

      caption.not(".caption--image").addClass("caption--bottom");
      $(".button-next, .button-previous").fadeIn(300);
    },
    beforeClose: function () {
      $(".button-next, .button-previous").css("display", "none");
    },
    caption: function (instance, item) {
      var caption = $(this).data("caption") || "";
      return (
        (caption.length ? "<p>" + caption + "</p>" : "") +
        "<p class='fancybox-pagination'><span data-fancybox-index></span> / <span data-fancybox-count></span></p>"
      );
    }
  });
}

// hover effect for more to celebrate
$(document).ready(function () {
  $('.pod-image-wrap').hover(function () {
    $(this).find('h4').toggleClass('go-red');
  });

  $('.home-explore').on('click', function () {
    $([document.documentElement, document.body]).animate({
      scrollTop: $(".home-explore-to").offset().top - 70
    }, 1300);
  });


  if ($('.more-from-image').length) {
    $('.more-from-image').hover((e) => {
      const el = $(e.currentTarget);
      $(el).attr('src', $(el).attr('data-hover'));
    }, (e) => {
      const el = $(e.currentTarget);
      $(el).attr('src', $(el).attr('data-src'));
    });
  }

 
  $('body').on('touchstart', '.more-from-image2', function(e){
    const el = $(e.currentTarget).find('img');
    if(el.attr("data-click") === "false"){
      $(el).attr('src', $(el).attr('data-hover'));
      el.attr("data-click", "true")
    }else{
      $(el).attr('src', $(el).attr('data-src'));
      el.attr("data-click", "false")
    }

  });

  const moreImg = document.querySelectorAll('.long-press-link');
  moreImg.forEach(v => {
     v.addEventListener('long-press', function(e) {
      e.preventDefault();
      e.stopPropagation();
      e.target.setAttribute('data-editing', 'true');
      $(this).click();

      const el = $(v).find('.more-from-image2');
      $(v).find('.more-from-image2').attr('src', $(el).attr('data-src'))
    });
  })
});